<template>
  <q-page-container class="first-component">
    <div :class="$q.platform.is.desktop ? 'row' : 'column turn_back'">
      <div :style="$q.platform.is.desktop ? 'width: 60%' : 'width: 100%;'">
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-24' : 'text-45') +
            '  bold-weight-font black-font'
          ">
          {{ $t('homePage.first.unlimited') }}
        </p>
        <div style="height: 89px" />
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
          ">
          {{ $t('homePage.first.highSpeed') }}
        </p>
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
          ">
          {{ $t('homePage.first.ensure') }}
        </p>
        <p
          :class="
            'black-font ' + ($q.screen.width <= 768 ? 'text-13' : 'text-16')
          ">
          {{ $t('homePage.first.hint') }}
        </p>
      </div>
      <div
        :style="
          ($q.platform.is.desktop ? 'width: 40%;' : 'width: 100%;') +
          'display: flex;align-items: center; justify-content: center;flex-direction: column;'
        ">
        <q-img src="../../../assets/home-first-component.png" width="100%" />
        <a
          style="text-decoration: underline; font-size: 10px"
          href="https://www.freepik.com/vectors/social-media-network"
          class="link">
          * GoDuck TLS is base on
          https://www.freepik.com/vectors/social-media-network
        </a>
      </div>
    </div>
    <div class="row">
      <q-btn
        @click="scrollToDownload"
        :class="'btn ' + ($q.screen.width <= 768 ? 'text-18' : 'text-24')"
        rounded
        :label="$t('global.download')" />
      <div style="width: 19px" />
      <q-btn
        :to="userStore.token ? '/pricing?lang=' + userStore.lang : '/pricing'"
        :class="'btn ' + ($q.screen.width <= 768 ? 'text-18' : 'text-24')"
        rounded
        :label="$t('global.purchase')" />
    </div>
    <div style="height: 38px" />
    <!-- <p
      :class="'white-font ' + ($q.screen.width <= 768 ? 'text-13' : 'text-16')">
      {{ $t('homePage.first.hint') }}
    </p> -->
  </q-page-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useQuasar } from 'quasar'
import { useUserStore } from 'src/stores/user'
import '../css/index.css'

export default defineComponent({
  setup() {
    const $q = useQuasar()
    const userStore = useUserStore()

    const scrollToDownload = () => {
      document.getElementById('download').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
    return {
      $q,
      scrollToDownload,
      userStore
    }
  }
})
</script>
