import axios from 'axios'
import { api } from 'src/boot/axios'

const urlHelp = 'https://matrixnetworkinghelp.zendesk.com/api/v2/help_center/'
// const url = 'https://matrixnetworkinghelp.zendesk.com/api/v2/tickets'

export const getCategories = async () => {
  let data
  await axios.get(urlHelp + 'categories').then((res) => {
    data = res.data.categories
  })

  return data
}

export const getArticles = async (sectionId) => {
  let data
  await axios.get(`${urlHelp}sections/${sectionId}/articles`).then((res) => {
    data = res.data.articles
  })
  return data
}

export const getSections = async (categoryId) => {
  let data
  await axios.get(`${urlHelp}categories/${categoryId}/sections`).then((res) => {
    data = res.data.sections
  })
  return data
}

export const createTicket = (data) => {
  return api({
    url: 'notice/ticket',
    method: 'post',
    data: {
      Subject: data.subject,
      Comment: data.message,
      Name: data.name,
      Email: data.email
    }
  })
}
