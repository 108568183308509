<template>
  <q-page-container class="third-component">
    <p
      :class="
        ($q.screen.width <= 768 ? 'text-24' : 'text-45') +
        ' black-font text-center  bold-weight-font'
      ">
      {{ $t('homePage.third.title') }}
    </p>
    <div class="outer-container row">
      <div @click="changeUrl('Windows')" class="inner-container pointerCursor">
        <q-img src="../../../assets/windowsIcon.png" width="100%" />
        <!-- <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' spacing-15'
          ">
          Windows
        </p> -->
        <!-- <q-tooltip :class="$q.screen.width <= 768 ? 'text-14' : 'text-16'">
          {{ $t('homePage.third.notAvailable') }}
        </q-tooltip> -->
      </div>
      <div @click="changeUrl('macOS')" class="inner-container pointerCursor">
        <q-img src="../../../assets/macIcon.png" width="100%" />
        <!-- <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' spacing-15'
          ">
          macOS
        </p> -->
        <!-- <q-tooltip :class="$q.screen.width <= 768 ? 'text-14' : 'text-16'">
          {{ $t('homePage.third.notAvailable') }}
        </q-tooltip> -->
      </div>
      <div @click="changeUrl('Android')" class="inner-container pointerCursor">
        <q-img src="../../../assets/androidIcon.png" width="100%" />
        <!-- <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' spacing-15'
          ">
          Android
        </p> -->
      </div>
      <div @click="changeUrl('iOS')" class="inner-container pointerCursor">
        <q-img src="../../../assets/iosIcon.png" width="100%" />
        <!-- <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' spacing-15'
          ">
          iPhone
        </p> -->
        <!-- <q-tooltip :class="$q.screen.width <= 768 ? 'text-14' : 'text-16'">
          {{ $t('homePage.third.notAvailable') }}
        </q-tooltip> -->
      </div>
    </div>
  </q-page-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import '../css/index.css'
import { useUserStore } from 'src/stores/user'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const changeUrl = (download) => {
      router.push({
        path: '/download',
        query: { platform: download, lang: userStore.lang }
      })
    }
    return {
      changeUrl,
      userStore
    }
  }
})
</script>
