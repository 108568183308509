<template>
  <q-page-container class="column fourth-component">
    <div class="top-cont">
      <p
        :class="
          ($q.screen.width <= 768 ? 'text-24' : 'text-45') +
          '  bold-weight-font black-font'
        ">
        {{ $t('homePage.fourth.title') }}
      </p>
      <div style="height: 30px" />
      <!-- <p
        :class="
          ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
        ">
        {{ $t('homePage.fourth.descOne') }}
      </p>
      <p
        :class="
          ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
        ">
        {{ $t('homePage.fourth.descTwo') }}
      </p> -->
      <p
        :class="
          ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
        ">
        info@goduck.io
      </p>
      <div style="height: 30px" />
      <div class="row icon-contact">
        <span class="rounded-image">
          <q-img
            @click="linkSocial('facebook')"
            src="../../../assets/facebook-icon.png"
            height="26px"
            width="26px" />
        </span>
        <span class="rounded-image">
          <q-img
            @click="linkSocial('twitter')"
            class="rounded-image"
            src="../../../assets/twitter-icon.png"
            height="26px"
            width="26px" />
        </span>
        <span class="rounded-image">
          <q-img
            @click="linkSocial('instagram')"
            class="rounded-image"
            src="../../../assets/insta-icon.png"
            height="26px"
            width="26px" />
        </span>
      </div>
    </div>
    <div style="height: 50px" />
    <q-form @submit="formSubmit(true)" id="input" class="q-gutter-md">
      <div v-for="item in formInput" :key="item.name">
        <q-input
          v-model="item.model"
          lazy-rules
          class="fourth-input"
          :label="item.label"
          :rules="item.rules"
          :type="item.type"
          bg-color="white"
          autocomplete="off"
          :counter="item.counter"
          color="black"
          outlined />
      </div>
      <div class="contact-btn">
        <q-btn
          :class="
            'btn black-font ' + ($q.screen.width <= 768 ? 'text-18' : 'text-24')
          "
          rounded
          style="width: 45%"
          :label="$t('homePage.fourth.submit')"
          type="submit"
          :loading="isLoading"
          color="primary" />
      </div>
    </q-form>
    <p
      :class="'black-font ' + ($q.screen.width <= 768 ? 'text-13' : 'text-16')"
      style="text-align: center; margin-top: 10px"
      v-show="isSubmitted">
      {{ $t('homePage.fourth.success') }}
    </p>
  </q-page-container>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useUserStore } from 'src/stores/user'
import '../css/index.css'
import { useI18n } from 'vue-i18n'
import { useQuasar } from 'quasar'
import { createTicket } from 'src/api/article'
import { useRoute } from 'vue-router'

export default defineComponent({
  // data() {
  //   return {
  //     isLoading: false,
  //     isSubmitted: false
  //   }
  // },
  created() {
    // const route = useRoute()
    // const formParams = ref({
    //   name: route.query.name,
    //   email: route.query.email,
    //   subject: route.query.subject,
    //   message: route.query.msg
    // })
    // const formSubmit = async () => {
    //   isLoading.value = true
    //   await createTicket(formParams.value)
    //     .then(() => {
    //       isSubmitted.value = true
    //     })
    //     .catch((err) => {
    //       $q.notify({
    //         type: 'negative',
    //         message: t('errorCode.' + err.code),
    //         position: 'top'
    //       })
    //     })
    //     .finally(() => {
    //       isLoading.value = false
    //     })
    // }
    // if (
    //   route.query.name &&
    //   route.query.email &&
    //   route.query.subject &&
    //   route.query.msg
    // ) {
    //   // window.scrollTo(0, 4500)
    //   // formSubmit()
    // }
  },
  setup() {
    const userStore = useUserStore()
    const { t } = useI18n()
    const $q = useQuasar()
    const isEmailValid = ref(false)
    const route = useRoute()
    const isLoading = ref(false)
    const isSubmitted = ref(false)
    const formParams = ref({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
    const formName = ref(null)
    const formEmail = ref(null)
    const formSubject = ref(null)
    const formMessage = ref(null)
    const checkEmailFormat = (val) => {
      const reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      isEmailValid.value = reg.test(val)
      if (isEmailValid.value) {
        return true
      } else {
        return false
      }
    }
    const formInput = ref([
      {
        name: 'name',
        label: t('homePage.fourth.name'),
        rules: [(val) => !!val || ''],
        model: formName,
        type: 'text'
      },
      {
        name: 'email',
        label: t('homePage.fourth.email'),
        rules: [
          (val) => !!val || '',
          (val) =>
            checkEmailFormat(val) || t('homePage.fourth.emailFormatFault')
        ],
        model: formEmail,
        type: 'text'
      },
      {
        name: 'subject',
        label: t('homePage.fourth.subject'),
        rules: [(val) => !!val || ''],
        model: formSubject,
        type: 'text'
      },
      {
        name: 'message',
        label: t('homePage.fourth.message'),
        rules: [
          (val) => !!val || '',
          (val) => val.length < 500 || t('homePage.fourth.messageFault')
        ],
        model: formMessage,
        type: 'textarea',
        counter: true
      }
    ])
    const formSubmit = async (val) => {
      if (val) {
        formParams.value.email = formEmail.value
        formParams.value.name = formName.value
        formParams.value.subject = formSubject.value
        formParams.value.message = formMessage.value
      }
      isLoading.value = true
      await createTicket(formParams.value)
        .then(() => {
          isSubmitted.value = true
        })
        .catch((err) => {
          $q.notify({
            type: 'negative',
            message: t('errorCode.' + err.code),
            position: 'top'
          })
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    const linkSocial = (val) => {
      if (val === 'facebook') {
        window.open('https://www.facebook.com/profile.php?id=100077641068559')
      } else if (val === 'twitter') {
        window.open(
          'https://twitter.com/GoDUCK_vpn?s=20&t=uRyU98JXG2Cautuo0Q8mtw'
        )
      } else if (val === 'instagram') {
        window.open('https://instagram.com/goduckvpn?igshid=YmMyMTA2M2Y=')
      }
    }
    if (
      route.query.name &&
      route.query.email &&
      route.query.subject &&
      route.query.msg
    ) {
      formParams.value.name = route.query.name
      formParams.value.email = route.query.email
      formParams.value.subject = route.query.subject
      formParams.value.message = route.query.msg
      window.scrollTo(0, 4500)
      formSubmit(false)
    }
    return {
      $q,
      userStore,
      formInput,
      isLoading,
      isSubmitted,
      linkSocial,
      formSubmit
    }
  }
})
</script>
