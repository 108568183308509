<template>
  <section
    :class="
      $q.platform.is.desktop ? 'desktopFirst' : 'phoneFirst' + ' firstComponent'
    ">
    <FirstComponent />
  </section>
  <section class="secondComponent">
    <SecondComponent />
  </section>
  <section class="thirdComponent" id="download">
    <ThirdComponent />
  </section>
  <section class="fourthComponent">
    <FourthComponent />
  </section>
  <AppFooter />
</template>

<script setup>
import { useMeta } from 'quasar'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AppFooter from 'src/components/Footer.vue'
import FourthComponent from './components/FourthComponent.vue'
import FirstComponent from './components/FirstComponent.vue'
import SecondComponent from './components/SecondComponent.vue'
import ThirdComponent from './components/ThirdComponent.vue'
import { metaLinkLocale, metaTitleTemplate } from 'src/utils/util'

const route = useRoute()
const { t, availableLocales } = useI18n()

useMeta({
  title: t('homePage.title'),
  titleTemplate: metaTitleTemplate,
  meta: {
    description: {
      name: 'description',
      content: t('homePage.description')
    }
  },
  link: metaLinkLocale(route.fullPath, availableLocales)
})
</script>

<style>
/* settings for either height */
.firstComponent {
  min-height: 615px;
  padding-bottom: 16px;
}
.desktopFirst {
  background-image: url('../../assets/home-first-background-desktop.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.phoneFirst {
  background-image: url('../../assets/home-first-background-phone.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.secondComponent {
  min-height: 950px;
  padding-bottom: 100px;
}
.thirdComponent {
  padding-bottom: 100px;
  background-color: var(--main-yellow);
  /* background-image: url('../../assets/home-first-background.png'); */
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.fourthComponent {
  min-height: 705px;
  padding-bottom: 44px;
  background-color: var(--base-white);
  /* background-image: url('../../assets/home-fourth-background.png'); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
}

@media screen and (max-width: 768px) {
  .firstComponent {
    min-height: 500px;
  }

  .secondComponent {
    min-height: 500px;
  }
}

.q-page-container {
  padding-bottom: 0 !important;
}
</style>
